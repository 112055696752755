<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">User</h1>
            </div>
        </div>

        
        <div>
            <div class="panel panel-blur with-scroll animated zoomIn" style="max-width:800px">
                <div class="panel-heading clearfix">
                    <h3 class="panel-title">{{formTitle}}</h3>
                </div>
                <div class="panel-body">

                    <div>
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">Group</label>
                                <div class="col-sm-10">

                                    <select class="form-control mb-1" id="GroupId">
                                        <option v-for="item in groups" :key="item.Id" :value="item.Id" :selected="editItem.GroupId==item.Id?'true':null">{{item.Name}}</option>
                                        
                                    </select>
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">UserName</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control mb-1" id="username" @keyup="nameKeyup" :value="editItem.UserName" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">Password</label>
                                <div class="col-sm-10">
                                    <input type="password" class="form-control mb-1" id="password" @keyup="passwordCheck" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="panel-heading clearfix">
                    <h3 class="panel-title">User Information</h3>
                </div>
                <div class="panel-body">

                    <div>
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">First Name</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control mb-1" id="Fname" :value="editItem.Fname" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">Last Name</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control mb-1" id="Lname" :value="editItem.Lname"/>
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">Email</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control mb-1" id="Email" @keyup="emailCheck" :value="editItem.Email" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">Phone</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control mb-1" id="Phone" :value="editItem.Phone" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">Address</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control mb-1" id="Address" :value="editItem.Address" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">Date of Birth</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control mb-1" id="Dob" :value="editItem.Dob" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                </div>

                <div class="panel-heading clearfix">
                    <h3 class="panel-title">Employee Information</h3>
                </div>
                <div class="panel-body">

                    <div>
                        <form class="form-horizontal">
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">Department</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control mb-1" id="Department" :value="editItem.Department" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="Name" class="col-sm-2 control-label">Position</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control mb-1" id="Position" :value="editItem.Position" />
                                    <i class="ion-alert-circled form-control-feedback"></i>
                                    <span class="help-block red-text basic-block"></span>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-sm-2 control-label"></label>
                                <div class="col-sm-10">
                                    <button class="btn btn-primary" @click.prevent="save">{{btnSaveText}}</button>
                                    <button class="btn btn-danger ml-3" @click.prevent="$router.push('/user')">Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    select option {

        background: rgba(0, 0, 0, 0.3);
        color: #fff;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }

</style>
<script>
    import $ from 'jquery'
    import _ from 'lodash'
    import { mapState, mapActions } from 'vuex'
    export default {
        name: `UserEdit`,
        props: {

        },
        data: function () {
            return {
                editItem: {
                    Id: 0,
                    UserName: '',
                    Password: '',
                    Fname: '',
                    Lname: '',
                    Department: '',
                    Position: '',
                    Phone: '',
                    Address: '',
                    Email: '',
                    Dob: '',
                    GroupId: 0
                },
                LayoutModel: {},                                
                btnSaveText: 'Add',
                formTitle: 'New User',
                idEdit: 0,
                validateName: _.debounce(() => {
                    var nameEl = document.getElementById('username');
                    
                    this.call({ Action: 'User/CheckName', Object: { Name: nameEl.value, Id: this.idEdit } }).then((rs) => {
                        nameEl.parentElement.classList.add('has-feedback');
                        nameEl.parentElement.classList.add('has-error');
                        var err = rs.Error;                        
                        if (err != "") {
                            err= 'Duplicate! Please choose another name.';
                        }
                        this.inputMark('username',err);
                        

                    });

                }, 1000),
            }
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
            groups: state => state.user.groups,
            
            index: state => state.user.index,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',                
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'call': 'api/call',
                'setGroups': 'user/setGroups',
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes("User/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                
                this.idEdit = parseInt(this.$route.params.id);
                
                if (isNaN(this.idEdit)) this.idEdit = 0;
                if (this.idEdit) {
                    this.getDetail();
                    this.formTitle = `User edit`;
                    this.btnSaveText = 'Save';                    
                }
                if (this.groups.length == 0) {
                    this.getAllGroup();
                }

            },
            async getAllGroup() {
                this.loading(true);
                const rs = await this.call({ Action: 'UserGroup/GetAll' })
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }
                } else {
                    this.setGroups(rs.Data);
                }
            },
            async getDetail() {
                if (this.idEdit == 0) return;
                this.loading(true);
                const rs = await this.call({ Action: 'User/Detail', Object: { Id: this.idEdit } });
                this.loading(false);
                this.error = rs.Error;
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    //no permission                    
                    if (rs.Error.includes("Permission Denied")) {
                        window.history.back();
                    }
                } else {
                    this.editItem = rs.Data;
                    console.log(this.editItem);
                }
            },
            nameKeyup() {
                var nameEl = document.getElementById('username');
                if (!nameEl.value || nameEl.value.trim() == "") {
                    this.inputMark('username', 'Please input Group name.');
                    this.validateName.cancel();
                } else {
                    nameEl.nextElementSibling.nextElementSibling.innerHTML = 'Checking...';
                    this.validateName();
                }
            },
           
            async save() {
                //validate
                var nameEl = document.getElementById('username');
                if (!nameEl.value || nameEl.value.trim() == "") {

                    nameEl.focus();
                    nameEl.parentElement.classList.add('has-error');
                    nameEl.parentElement.classList.add('has-feedback');
                    nameEl.nextElementSibling.nextElementSibling.innerHTML = 'Please input Group name.';
                    return;
                }
                //get selected acl
                const acl = [];
                $('.jstree-clicked:not(.jstree-parent)').each((i, item) => {
                    acl.push(item.getAttribute("data"));
                });

                let Action = "User/Add";
                //let data = {UserName:}
                if (this.idEdit) Action = "User/Edit";

                this.loading(true);
                const rs = await this.call({
                    Action: Action, Object: {
                        Id: this.idEdit,
                        UserName: nameEl.value,
                        Password: $("#password").val(),
                        Fname: $("#Fname").val(),
                        Lname: $("#Lname").val(),
                        Department: $("#Department").val(),
                        Position: $("#Position").val(),
                        Phone: $("#Phone").val(),
                        Address: $("#Address").val(),
                        Email: $("#Email").val(),
                        Dob: $("#Dob").val(),
                        GroupId: $("#GroupId").val()                        
                    }
                })
                this.loading(false);

                if (rs.Error != "") {                    
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    this.showToast({ isShow: true, title: '', content: (this.idEdit != 0?'Save':'Create')+` user "${nameEl.value}" successfull`, type: 'success' });
                    this.$router.push("/user");
                }
            },
           
            inputMark(elId, msg) {
                var nameEl = document.getElementById(elId);
                msg = msg || '';//default value
                //if success
                if (msg == '') {
                    nameEl.parentElement.classList.remove('has-error');
                    nameEl.parentElement.classList.add('has-success');
                    nameEl.nextElementSibling.classList.remove('ion-alert-circled');
                    nameEl.nextElementSibling.classList.add('ion-checkmark-circled');
                    nameEl.nextElementSibling.nextElementSibling.innerHTML = "&nbsp";                    
                }
                //if error
                else {
                    nameEl.parentElement.classList.add('has-error');
                    nameEl.parentElement.classList.remove('has-success');
                    nameEl.nextElementSibling.classList.add('ion-alert-circled');
                    nameEl.nextElementSibling.classList.remove('ion-checkmark-circled');
                    nameEl.nextElementSibling.nextElementSibling.innerHTML = msg;
                    nameEl.focus();
                }
            }
        },
        mounted() {
            //if already login then start,
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();

            
        },
        watch: {
            //catch status from not login to logged in
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                if (newVal) {
                    this.start();
                }
            }
        }
    };
</script>
